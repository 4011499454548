<template>
  <div class="contact-view">
    <transition name="bounce">
      <Announcement v-if="showAnnouncement && !brokerData.slug" v-on:close-event="showAnnouncement = false"/>
    </transition>
    <div class="top-wrapper">
      <ButtonClose v-on:click-event="$emit('close-event')"/>
    </div>
    <div class="content-wrapper">
      <div class="werbepartner" v-if="brokerData.slug">
        <p class="title">Dein Ansprechpartner</p>
        <!-- <div class="contact-info">
        </div> -->
        <div class="logo-wrapper">
          <img :src="brokerData.logo" alt="logo">
        </div>
        <p>{{ brokerData.message }}</p>
        <!-- <p>Die Werbepartner bietet diverse Sparformen insbesondere für die Altersvorsorge an.</p> -->
        <a v-if="brokerData.phone" :href="'tel:'+brokerData.phone">
          <div class="contact-info phone">
            <div class="wrapper">
              <div class="icon-wrapper">
                <i class="icon icn-msg" />
              </div>
              <span>{{brokerData.phone}}</span>
            </div>
            <div class="icon-wrapper">
              <i class="icon icn-arrow-right" />
            </div>
          </div>
        </a>
        <a v-if="brokerData.email" :href="'mailto:'+brokerData.email">
          <div class="contact-info">
            <div class="wrapper">
              <div class="icon-wrapper">
                <i class="icon icn-mail" />
              </div>
              <span>{{brokerData.email}}</span>
            </div>
            <div class="icon-wrapper">
              <i class="icon icn-arrow-right" />
            </div>
          </div>
        </a>
        <a v-if="brokerData.website" :href="brokerData.website" target="_blank">
          <div class="contact-info">
            <div class="wrapper">
              <div class="icon-wrapper">
                <i class="icon icn-www" />
              </div>
              <span>{{ brokerData.website.split('://')[1] }}</span>
            </div>
            <div class="icon-wrapper">
              <i class="icon icn-arrow-right" />
            </div>
          </div>
        </a>
        <br>
        <br>
      </div>
      <div class="verbraucherzentrale" v-else >
        <p class="title">Lass dich beraten!</p>
        <!-- <p class="strong">Lass dich beraten</p> -->
        <p>Du benötigst weitere Informationen rund ums Sparen? Dann vereinbare einen Termin mit einem Finanzspezialisten. Du kannst mit einem Makler, deinem Bankberater oder mit einem Ansprechpartner deiner örtlichen Verbraucherzentrale über deine persönlichen Sparziele sprechen und mit ihm Produkte und Anbieter vergleichen.</p>
        <p class="strong">Die Verbraucherzentralen</p>
        <p>Deutschlandweit gibt es rund 200 Beratungsstellen, in denen du dich über die Sparen und Vorsorge informieren kannst. Die Beratungsstelle in deiner Nähe findest du auf der Website des Bundesverbandes.</p>

        <div class="contact-info">
          <div class="wrapper">
            <div class="icon-wrapper">
              <i class="icon icn-www" />
            </div>
            <span>
              <a href="mailto:kontakt@Verbraucherzentrale.de">kontakt@verbraucherzentrale.de</a>
            </span>
          </div>
          <div class="icon-wrapper">
            <i class="icon icn-arrow-right" />
          </div>
        </div>
        <div class="contact-info">
          <div class="wrapper">
            <div class="icon-wrapper">
              <i class="icon icn-www" />
            </div>
            <span>
              <a href="https://www.verbraucherzentrale.de/" target="_blank">verbraucherzentrale.de</a>
            </span>
          </div>
          <div class="icon-wrapper">
            <i class="icon icn-arrow-right" />
          </div>
        </div>

        <p class="partner-info strong">Du willst hier als Werbepartner erscheinen? Dann schau <a href="https://landing-staging.thrifter.de" target="_blank">hier</a> vorbei.</p>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonClose from '@/components/ButtonClose.vue'
import Announcement from './Announcement.vue';

export default {
  name: 'ContactView',
  components: {
    ButtonClose, Announcement
  },
  data() {
    return {
      state: this.$store.state,
      brokerData: this.$store.brokerData,
      showAnnouncement: false,
    }
  },
  props: {
  },
  mounted() {
    setTimeout(() => {
      this.showAnnouncement = true;
    }, 3000);
  },
  methods: {
    
  }
}
</script>

<style lang="stylus" scoped>
  .contact-view
    height 0
    width 100%
    position fixed
    bottom 0
    background-color #fff
    z-index 10
    transition: all 0.3s ease-out;
    box-shadow 0 -5px 15px rgba(0, 0, 0, 0.66);
    overflow scroll
    z-index 99

    &.is-visible
      height 50vh
      transition: all 0.3s ease-out;

    .top-wrapper
      padding 6.4vw 6.4vw 0
      margin-bottom 4.3vw
      display: flex;
      justify-content: flex-end;

    .content-wrapper
      p
        padding 0 6.4vw
        font-family "Hind"
        color #0D0E21
        font-weight 500
        font-size 4.8vw
        line-height 6.4vw
        text-align left

        &.title
          font-weight 700
          text-transform uppercase
          margin-bottom: 0;

        &.strong
          font-weight 700
          // text-transform uppercase
          margin-bottom: 0;

      .logo-wrapper
        text-align: left 
        padding: 0 6.4vw
        margin-top: 0.5rem
        margin-bottom: 1rem

        img 
          max-width: none;
          width: auto;
          height: 6.1vw;
      .partner-info 
        padding-top: 30px; 
      .contact-info
        border-bottom  1px solid rgba(13, 14, 33, .1);
        padding 0 6.4vw
        font-family "Hind"
        color #0D0E21
        font-weight 500
        font-size 4.8vw
        line-height 6.4vw
        text-align left
        height: 14.934vw
        display: flex
        align-items: center
        justify-content: space-between

        .wrapper
          display flex
          align-items center
          
          span
            margin-left 4.3vw

            a
              color: #0D0E21;

        .icon-wrapper
          height 8.533vw
          width 8.533vw
          display: flex;
          justify-content: center;
          align-items: center;
          
          .icon
            display block
            width 6.8vw
            height 6.8vw
            background-color #0D0E21

            &.icn-msg
              // background-image url('~@/assets/icons/message.svg')
              mask-image: url('~@/assets/icons/message.svg')
            &.icn-mail
              // background-image url('~@/assets/icons/mail.svg')
              mask-image: url('~@/assets/icons/mail.svg')
            &.icn-www
              // background-image url('~@/assets/icons/web.svg')
              mask-image: url('~@/assets/icons/web.svg')
            &.icn-arrow-right
              mask-image: url('~@/assets/icons/chevron_right.svg')
              // width 6vw
              // height 6vw
        

  .bounce-enter-active {
    animation: bounce-in 0.35s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.35s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    50% {
      transform: scale(1.15);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  
</style>